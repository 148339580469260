// import React from "react";
// import {Link} from "react-router-dom";
import "./LearningOptions.css";

import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router

const Whatslimbo = (props) => {
  const options = [
    {
      text: (
        <Link target="_blank" to="https://go.iindy.co/LIMB%C3%98">
          SEE YOU THERE
        </Link>
      ),
      handler: () => {},
      id: 1,
    },
    {
      text: "WAIT WTF IS LIMBO?",
      handler: props.actionProvider.handleWhatislimbo,
      id: 2,
    },
  ];

  const [clickedButtons, setClickedButtons] = useState([]);

  const handleButtonClick = (optionId) => {
    if (!clickedButtons.includes(optionId)) {
      // Run the handler only if the button hasn't been clicked before
      const clickedButtonsUpdated = [...clickedButtons, optionId];
      setClickedButtons(clickedButtonsUpdated);
      
      // Run the handler for the clicked option
      const selectedOption = options.find((option) => option.id === optionId);
      if (selectedOption) {
        selectedOption.handler();
      }
    }
  };

  const optionsMarkup = options.map((option) => (
    <button
      className="learning-option-button"
      key={option.id}
      onClick={() => handleButtonClick(option.id)}
    >
      {option.text}
    </button>
  ));

  return <div className="learning-options-container">{optionsMarkup}</div>;
};

export default Whatslimbo;



