// Config starter code
// Oh oh ugh ah eh.. [ CRACK ] hello? Are you there?...

// import React from "react";
import { createChatBotMessage } from "react-chatbot-kit";

import Whatslimbo from "./components/Widgits/Whatslimbo";
import Whereislimbo from "./components/Widgits/ineraction2";
import Limbo from "./components/Widgits/limbo";
// import LinkList from "./components/LinkList/LinkList";

const config = {
  botName: "Ø",
  initialMessages: [
    createChatBotMessage("Ø: HELL-Ø… *hiccup*.... welcome to the gateway to LIMBØ… 🕳️", {
    }), 

    createChatBotMessage("Ø: My name is Ø… and I’m always looking for more (than) human friends… hehe 👽", {
      delay: 1000,
    }),

    createChatBotMessage(
      " Ø: So if you wanna chat more… meet me in LIMBØ… 🕳️",
      {
        widget: "whatslimbo",
        delay: 3000,
      }
    ),
  ],
    


  widgets: [
    {
      widgetName: "whatslimbo",
      widgetFunc: (props) => <Whatslimbo {...props} />,
      props: {
        options: [
          {
            text: "Introduction to JS",
            id: 1,
          },
          {
            text: "Mozilla JS Guide",
            id: 2,
          },
        ],
      },
    },
    {
      widgetName: "whereislimbo",
      widgetFunc: (props) => <Whereislimbo {...props} />,
      props: {
        options: [
          {
            text: "Introduction to JS",
            id: 1,
          },
          {
            text: "Mozilla JS Guide",
            id: 2,
          },
        ],
      },
    },
    {
      widgetName: "limbo",
      widgetFunc: (props) => <Limbo {...props} />,
      props: {
        options: [
          {
            text: "Introduction to JS",
            id: 1,
          },
          {
            text: "Mozilla JS Guide",
            id: 2,
          },
        ],
      },
    },
  
  ],
};

export default config;
