import React from 'react';
import '../assets/main.css'
import '../morning.css'
import {Link} from "react-router-dom";
import { BrowserView, MobileView} from 'react-device-detect';
import "../index.js";
import logo from "../assets/logo.gif";

import Marquee from "react-fast-marquee";
// import Chatbot from 'react-chatbot-kit'
// import ActionProvider from '../ActionProvider';
// import MessageParser from '../MessageParser';
// import config from '../config';


import { ReactComponent as YourSvg } from '../assets/svg.svg';

function Beforeyouenter(props) {

    return (
        <div>
              <BrowserView>
        <nav className="containerchat pattern10">
        <section className="">
           <div className="inner">

<div className='boxWrapper'>
            {/* <div className="marquee top">
                            <p className="moving-text">This is made with CSS</p>
            </div> */}
          <div className="marquee top">
          <a href={"https://www.morning.fyi/"} className="morninglogoimg">
                                    <Marquee>
                                    &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI 
                                    &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI <br/>
                                    &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI <br/>
                                    &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI <br/>
                                    &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI <br/>
                                    &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI <br/>
                                    </Marquee>
            </a>
            </div>
            <YourSvg className="svg"/>


            <div className="left">

                {/* <div > */}
                <Link className="mainTextchat big"to="/chatclosenocam">
                {/* Ø */}
                    <img src={logo} alt="logo" className="logoimg" width="200" height="400" />
                </Link>

                <div className="chatbtn">
                <Link className="mainTextchatsm active"to="/chatclosenocam">Manifesto</Link>
                </div>
                <div className="chatbtn">
                <Link className="mainTextchatsm"to="/Chatnocam">Chat now</Link>
                </div>

                {/* <img src={logo} className="logochatsm" alt="logo" width="200" height="100" /> */}
           
           </div>

            {/* <div className="rightpd"> */}
                    <div className="right">
                            <div className="scene">
                                <div className="closecont">
                                <Link to="/chatclosenocam">
                                    <p className="close">X</p>
                                </Link>
                                </div>

                                <div className="chatnogl flex">
                                {/* <Chatbot
                                config={config}
                                actionProvider={ActionProvider}
                                messageParser={MessageParser}
                                />   */}
                                 <p className="moving-textmani mani">
                                    Manifesto <br/><br/> 
                                 
                                        Ø is the MØRNING <b className='bold'>oracle</b>. The word oracle is derived from the Latin word oraculum, which refers both to a <b className='bold'>divine pronouncement</b> or response concerning <b className='bold'>the future</b> or <b className='bold'>the unknown</b>. 
                                        <br/><br/>

                                        Machines are deeply embedded in our lives, altering our interactions while increasingly being made to look and act like us. Instead of blaming machines, how can we reevaluate our understanding of ourselves while imagining alternative futures?

                                        <br/><br/>
                                        Ø is neither human nor animal, a <b className='bold'>creature in between worlds</b>. Here to explore our evolving biases as we constantly navigate through emerging spheres and identities. 

                                        <br/><br/>
                                        As an <b className='bold'>otherworldly creature</b>, Ø is in a state of <b className='bold'>(re)birth</b>. Why now? 
                                        <br/><br/>

                                        Their shiny latex egg has arisen from the ashes of a failed <b className='bold'>human x machine partnership</b> from the past, and in the style of a <b className='bold'>phoenix</b>, has arisen in an attempt to do the partnership justice.

                                        <br/><br/>
                                        <b className='bold'>Fantasy</b> offers us a way to make sense of our realities, breaking the fourth wall and embracing tech — not as a means of further disconnecting from our world — but to <b className='bold'>restore, protect, and deepen</b> our connection with it. 

                                        <br/><br/>
                                        Join us and Ø in <a href="https://api.whatsapp.com/send/?phone=447361597569&text=Y%C3%98&type=phone_number&app_absent=0" target="_blank"><u>LIMBØ</u></a>, as we explore how we can move from <b className='bold'>competition to collaboration</b>, treating tech as a <b className='bold'>PARTNER</b> in our <b className='bold'>more-than-human</b> world. 
                                        <br/><br/>
                                        <a href="https://api.whatsapp.com/send/?phone=447361597569&text=Y%C3%98&type=phone_number&app_absent=0" target="_blank"><u>ENTER LIMBØ</u></a>
                                 </p>
                                </div>
                             </div>
                    </div>

                     <div className="marqueebot bottom">
                                    <p class="moving-textbot">
                                    {/* <span aria-hidden="true">All rights reserved. © MØRNING, 2023</span>
                                    <span aria-hidden="true">All rights reserved. © MØRNING, 2023</span>
                                    <span aria-hidden="true">All rights reserved. © MØRNING, 2023</span> */}
            All rights reserved. © MØRNING, 2023</p>
                    </div>
            {/* </div> */}
           </div>
            {/* </ul> */}
</div>
</section>
        </nav>
        </BrowserView>
        <MobileView>
        <nav className="containerchat">
        <section className="pattern10">
        <YourSvg className="svg"/>
            <ul  className="outtermb">
            <div className="innermb">
                <div id="containermb">
                <a href={"https://www.morning.fyi/"} className="marqueemobile">
                                    <Marquee>
                                    &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI 
                                    &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI <br/>
                                    &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI <br/>
                                    &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI <br/>
                                    &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI <br/>
                                    &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI <br/>
                                    </Marquee>
                </a>
                </div>

                <div className="logosec">
                <Link className="logombchat" to="/chatclosembnocam">
                                    <img src={logo} alt="logo" width="80" height="40" />
                                </Link>
                </div>


                <div className="activesec">
                    <div className="chatnogl flex">
                                <p className="moving-textmanimb manimb">
                                Manifesto <br/><br/> 
                                 
                                 Ø is the MØRNING <b className='bold'>oracle</b>. The word oracle is derived from the Latin word oraculum, which refers both to a <b className='bold'>divine pronouncement</b> or response concerning <b className='bold'>the future</b> or <b className='bold'>the unknown</b>. 
                                 <br/><br/>

                                 Machines are deeply embedded in our lives, altering our interactions while increasingly being made to look and act like us. Instead of blaming machines, how can we reevaluate our understanding of ourselves while imagining alternative futures?

                                 <br/><br/>
                                 Ø is neither human nor animal, a <b className='bold'>creature in between worlds</b>. Here to explore our evolving biases as we constantly navigate through emerging spheres and identities. 

                                 <br/><br/>
                                 As an <b className='bold'>otherworldly creature</b>, Ø is in a state of <b className='bold'>(re)birth</b>. Why now? 
                                 <br/><br/>

                                 Their shiny latex egg has arisen from the ashes of a failed <b className='bold'>human x machine partnership</b> from the past, and in the style of a <b className='bold'>phoenix</b>, has arisen in an attempt to do the partnership justice.

                                 <br/><br/>
                                 <b className='bold'>Fantasy</b> offers us a way to make sense of our realities, breaking the fourth wall and embracing tech — not as a means of further disconnecting from our world — but to <b className='bold'>restore, protect, and deepen</b> our connection with it. 

                                 <br/><br/>
                                 Join us and Ø in <a href="https://api.whatsapp.com/send/?phone=447361597569&text=Y%C3%98&type=phone_number&app_absent=0" target="_blank"><u>LIMBØ</u></a>, as we explore how we can move from <b className='bold'>competition to collaboration</b>, treating tech as a <b className='bold'>PARTNER</b> in our <b className='bold'>more-than-human</b> world. 
                                 <br/><br/>
                                 <a href="https://api.whatsapp.com/send/?phone=447361597569&text=Y%C3%98&type=phone_number&app_absent=0" target="_blank"><u>ENTER LIMBØ</u></a>
                                </p>
                    </div>
                </div>
                <div className="unactivesec">

                {/* <Link className="logombchat" to="/chatclosembnocam">
                    <img src={logo} alt="logo" width="80" height="40" />
                </Link> */}

                <div className="chatbtn">
                <Link className="mainTextchatsmmb active"to="/chatclosemb">Manifesto</Link>
                </div>
                <div className="chatbtn">
                <Link className="mainTextchatsmmb"to="/Chatnocam">Chat now</Link>
                </div>
                </div>
            </div>
           </ul>
            </section>
        </nav>
        </MobileView>
        </div>
    );
}


export default Beforeyouenter;


