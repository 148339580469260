import React from 'react';
import '../assets/main.css'
import '../morning.css'
import '../morningmb.css'
import {Link} from "react-router-dom";
import useSound from 'use-sound'
import mySound from '../assets/yes.wav' 
import mySound2 from '../assets/no.wav'
import { BrowserView, MobileView } from 'react-device-detect';


function Home() {
    // const [playSound, { stop }] = useSound(mySound, { volume: 0.7 });
    const [playSound, { stop }] = useSound(mySound2, { volume: 0.3 });
   
    // pitch
    const [playbackRate, setPlaybackRate] = React.useState(0.75);
    const [play] = useSound(mySound, {
        playbackRate,
        volume: 0.3,
        // `interrupt` ensures that if the sound starts again before it's
        // ended, it will truncate it. Otherwise, the sound can overlap.
        interrupt: true,
      });
      const handleClick = () => {
        setPlaybackRate(playbackRate + 0.8);
        play();
      };

      

    return (
        <div>
             <BrowserView>

             <nav className="container">
{/* <Header/> */}
            <ul className="mainText" >
                <div>
                <div className="blur2 miniText">
                <p>This Website requires the use of your camera device.<br/><br/>
                                We won’t be capturing any data, it’s all just for a fun interaction with Ø.<br/>
                                You can also opt out of using the camera and interact with Ø in a more simple way.<br/><br/>
                                DO YOU WANT TO PROCEED ?
                 </p>
                </div>
                <div className="card">
                        <p>Would you prefer to interact <br/>with Ø using click and touch?
                        {/* <br/><br/>DO YOU WANT TO PROCEED? */}
                        </p>
                        {/* <p>Note: When entering you can choose to decline the web interaction 
                        <br/>and view and interact with Ø using click/touch
                        <br/><br/>DO YOU WANT TO PROCEED?
                        </p> */}
                        
                </div>
                <div className="miniminiText">
                            <div className="shake space" >
                            <Link to="/">
                            <button className="shakebutcon no" onClick={handleClick}>
                                No
                            </button>
                            </Link>
                            </div>


                            <div className="shake space " > 
                            <Link to="/Eggcracknope">
                            <button className="shakebutcon yes" onMouseEnter={() => playSound()} onMouseLeave={() => stop()}>
                                Yes 
                            </button>
                            </Link>
                            </div>
                </div> 
                </div>
            </ul>
        </nav>
             </BrowserView>
             <MobileView>
             <nav className="container">
            <ul className="mainTextmobile" >
            {/* <div className="blur2 miniText absolute">
                <div className="miniTextmobile">
                <p>This Website requires the use of your camera device.<br/><br/>
                                We won’t be capturing any data, it’s all just for a fun interaction with Ø.<br/>
                                You can also opt out of using the camera and interact with Ø in a more simple way.<br/><br/>
                                DO YOU WANT TO PROCEED ?
                            </p>
                </div>
                </div> */}
                <div className='nopecon'>
                <div className="cardmb">
                        <p>Would you prefer to interact <br/>with Ø using click and touch?
                        {/* <br/><br/>DO YOU WANT TO PROCEED? */}
                        </p>
                        {/* <p>Note: When entering you can choose to decline the web interaction 
                        <br/>and view and interact with Ø using click/touch
                        <br/><br/>DO YOU WANT TO PROCEED?
                        </p> */}
                        
                </div>
                {/* <div className="cardmobile">
                <p>
                        Would you prefer to interact with Ø using click and touch?
                        <br/><br/>DO YOU WANT TO PROCEED?
                        </p>
                </div> */}


                
                <div className="miniminiTextmobilebut">
                <li className="shake spacemb shakebutconmb nomb" >
                <button onClick={handleClick}>
                    {/* chatclosenocam */}
                    <Link to="/">No</Link>
                </button>
                </li>
                <br/><br/>
                <li className="shake spacemb shakebutconmb yesmb" >
                <button onMouseEnter={() => playSound()} onMouseLeave={() => stop()}>
                    <Link to="/Eggcracknope"> Yes </Link>
                </button>
                </li>
                </div>
                </div>
            </ul>
        </nav>
             </MobileView>
        </div>
    );
}


export default Home;

