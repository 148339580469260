class MessageParser {
  constructor(actionProvider) {
    this.actionProvider = actionProvider;
  }

  parse(message) {
    console.log(message);
    const lowercase = message.toLowerCase();

    if (lowercase.includes("hey || hey!")) {
      this.actionProvider.greet2();
    }

    if (lowercase.includes("👽")) {
      this.actionProvider.alien();
    }

    if (lowercase.includes("bye")) {
      this.actionProvider.bye();
    }

    if (lowercase.includes("again")) {
      this.actionProvider.again();
    }

    if (lowercase.includes("zine")) {
      this.actionProvider.again();
    }

    if (lowercase.includes("sorry")) {
      this.actionProvider.sorry();
    }

    if (lowercase.includes("hello") || lowercase.includes("hi") || (lowercase.includes("yo")) || (lowercase.includes("sup"))) {
      this.actionProvider.greet();
    }
   

    if (lowercase.includes("who are you")  || (lowercase.includes("what are you"))  ) {
      this.actionProvider.who();
    }


    // if (lowercase.includes("can i have more info") || (lowercase.includes("what are you")) || (lowercase.includes("im confused")) || (lowercase.includes("what")) ) {
    //   this.actionProvider.info();
    // }
 

    if (lowercase.includes("how old are you")){
      this.actionProvider.easteregg();
    }

    if (lowercase.includes("whats your name")){
      this.actionProvider.who();
    }

    if (lowercase.includes("what do you feel about ai ") ){
      this.actionProvider.ai();
    }

    if (lowercase.includes("what do you think about ai") ){
      this.actionProvider.AI2();
    }

    if (lowercase.includes("i am ai") ){
      this.actionProvider.AI4();
    }

    if (lowercase.includes("help") ){
      this.actionProvider.help();
    }

    if (lowercase.includes("humans machines") ){
      this.actionProvider.AI3();
    }

    if (lowercase.includes("friends") ){
      this.actionProvider.friends();
    }

    if (lowercase.includes("did you know") ){
      this.actionProvider.question();
    }

    if ( (lowercase.includes("i love you") ) ) {
      this.actionProvider.ok();
    }
  
    
    if ( (lowercase.includes("yes") ) ) {
      this.actionProvider.yes();
    }

    if ( (lowercase.includes("no") ) ) {
      this.actionProvider.no();
    }

    if (lowercase.includes("javascript") || lowercase.includes("js")) {
      this.actionProvider.handleJavascriptQuiz();
    }
  }
}

export default MessageParser;