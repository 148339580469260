import React from 'react';
import '../assets/main.css'
import '../morning.css'
import {Link} from "react-router-dom";
import { BrowserView, MobileView} from 'react-device-detect';
import "../index.js";
import logo from "../assets/logo.gif";


import Chatbot from 'react-chatbot-kit'
import ActionProvider from '../ActionProvider';
import MessageParser from '../MessageParser';
import config from '../config';
import Marquee from "react-fast-marquee";

import { ReactComponent as YourSvg } from '../assets/svg.svg';

function Beforeyouenter(props) {

    return (
        <div>
              <BrowserView>
        <nav className="containerchat pattern10">
        <section className="">
           <div className="inner">

        <div className='boxWrapper'>
                    <div className="marquee top">
                    <Marquee>
                                    &nbsp;&nbsp;  HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;     HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | 
                                    &nbsp;&nbsp;  HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;     HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | <br/>
                                    &nbsp;&nbsp;  HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;     HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | <br/>
                                    &nbsp;&nbsp;  HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;     HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | <br/>
                                    &nbsp;&nbsp;  HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;     HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | <br/>
                                    &nbsp;&nbsp;  HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;     HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | &nbsp;&nbsp;    HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | <br/>
                    </Marquee>
                                    {/* <p class="moving-text" HELL-Ø | NICE TO MEET YOU | WANNA CHAT MORE? | MEET ME IN LIMBØ… THIS IS JUST THE GATEWAY | </p> */}
                    </div>
                

                    <YourSvg className="svg"/>


                    <div className="left">

                        {/* <div > */}
                        <Link className="mainTextchat big"to="/">
                            {/* Ø */}
                                <img src={logo} className="logoimg" alt="logo" width="200" height="100" />
                         </Link>

                        <div className="chatbtn">
                        <Link className="mainTextchatsm"to="/Manifestonocam">Manifesto</Link>
                        </div>
                        <div className="chatbtn">
                        <Link className="mainTextchatsm active"to="/chatclosembnocam">Chat now</Link>
                        </div>

                        {/* <img src={logo} className="logochatsm" alt="logo" width="200" height="100" /> */}
                
                </div>

                    {/* <div className="rightpd"> */}
                            <div className="right">
                                    <div className="scene">
                                        <div className="closecont">
                                        <Link to="/chatclosembnocam">
                                            <p className="close">X</p>
                                        </Link>
                                        </div>
                                        <div className="chat">
                                        <Chatbot class="msg"
                                        config={config}
                                        actionProvider={ActionProvider}
                                        messageParser={MessageParser}
                                        />  
                                        
                                        </div>
                                    </div>
                            </div>
                    {/* </div> */}

                
                    <div className="marqueebot bottom">
                                    <p class="moving-textbot">
                                    {/* <span aria-hidden="true">All rights reserved. © MØRNING, 2023</span>
                                    <span aria-hidden="true">All rights reserved. © MØRNING, 2023</span>
                                    <span aria-hidden="true">All rights reserved. © MØRNING, 2023</span> */}
            All rights reserved. © MØRNING, 2023</p>
                    </div>
        </div>
            {/* </ul> */}
</div>
</section>
        </nav>
        </BrowserView>
        <MobileView>
        <nav className="containerchat">
        <section className="pattern10">
        <YourSvg className="svg"/>
            <ul  className="outtermb">
            <div className="innermb">
                <div id="containermb">
                <a href={"https://www.morning.fyi/"} className="marqueemobile">
                                    <Marquee>
                                    &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI 
                                    &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI <br/>
                                    &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI <br/>
                                    &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI <br/>
                                    &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI <br/>
                                    &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI <br/>
                                    </Marquee>
                </a>
                </div>

                <div className="logosec">
                <Link className="logombchat" to="/chatclosembnocam">
                                    <img src={logo} alt="logo" width="80" height="40" />
                                </Link>
                </div>


                <div className="activesec">
                    <div className="chatmb">
                    <Chatbot class="msg"
                                        config={config}
                                        actionProvider={ActionProvider}
                                        messageParser={MessageParser}
                     />  
                    </div>
                </div>
                <div className="unactivesec">

                {/* <Link className="logombchat"to="/chatclosembnocam">
                    <img src={logo} alt="logo" width="80" height="40" />
                </Link> */}

                <div className="chatbtn">
                <Link className="mainTextchatsmmb"to="/Manifestonocam">Manifesto</Link>
                </div>
                <div className="chatbtn">
                <Link className="mainTextchatsmmb active"to="/chatclosembnocam">Chat now</Link>
                </div>
                </div>
            </div>
           </ul>
            </section>
        </nav> 
        </MobileView>
        </div>
    );
}


export default Beforeyouenter;


