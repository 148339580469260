import React, { useState } from 'react';
import '../assets/main.css'
import '../morning.css'
import {Link} from "react-router-dom";
import { BrowserView, MobileView} from 'react-device-detect';
import "../index.js";
import logo from "../assets/logo.gif";
import Morning from "../pages/Morning";
// import Morningmb from "../pages/Morningmb";
import Marquee from "react-fast-marquee";
// import Chatbot from 'react-chatbot-kit'
// import ActionProvider from '../ActionProvider';
// import MessageParser from '../MessageParser';
// import config from '../config';
// src/components/LearningOptions/catheads.glb (162.42 MB)
// src/assets/eggcrack.mov (1219.39 MB)
// import Night from "../pages/Night";
// git filter-branch --force --index-filter "git rm --cached --ignore-unmatch src/assets/eggcrack.mov" --prune-empty --tag-name-filter cat -- --all

import { ReactComponent as YourSvg } from '../assets/svg.svg';

// import useSound from 'use-sound'
// import mySound from '../assets/talk.mp3'


 
function Beforeyouenter(props) {
    // const [playSound, { stop }] = useSound(mySound, { volume: 0.7 });
    // const [playSound, { stop }] = useSound(mySound2, { volume: 0.3 });


    // pitch
    // const [playbackRate, setPlaybackRate] = React.useState(0.75);
    // const [play] = useSound(mySound, {
    //     playbackRate,
    //     volume: 0.3,
    //     // `interrupt` ensures that if the sound starts again before it's
    //     // ended, it will truncate it. Otherwise, the sound can overlap.
    //     interrupt: true,
    //   });
    //   const handleClick = () => {
    //     setPlaybackRate(playbackRate);
    //     play();
    //   };

    return (
        <div>
              <BrowserView>

        <nav className="containerchat pattern10">
        <section className="">
           <div className="inner">
<div className='boxWrapper'>
            {/* <div className="marquee top">
                            <p className="moving-text">@MØRNING.FYI</p>
            </div> */}
            
            <div className="top">
            <a href={"https://www.morning.fyi/"} className="morninglogoimg">
                                <Marquee>
                                &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI 
                                &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI <br/>
                                &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI <br/>
                                &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI <br/>
                                &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI <br/>
                                &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI <br/>
                                </Marquee>
                                    {/* <p class="moving-text">@MØRNING.FYI</p> */}
                                    
                                    </a>
            </div>
            <YourSvg className="svg"/>
          
            {/* <div id="showMeoverlay" className="overlayprmtsm pattern10">
                                    <div className="promptmbchat">
                                    <p>*click and drag Ø*</p>
                                    <p>*open mouth to teach Ø*</p>
                                    <p>*Ø is learning from you blink and view*</p>
                                    </div>
            </div> */}


            <div className="left">

                {/* <div > */}
                <Link className="mainTextchat big"to="/">
                {/* Ø */}
                    <img src={logo} className="logoimg" alt="logo" width="200" height="100" />
                </Link>

                <div className="chatbtn">
                <Link className="mainTextchatsm" to="/Manifesto">Manifesto</Link>
                </div>
                <div className="chatbtn">
                <Link className="mainTextchatsm" to="/Chat">Chat now</Link>
                </div>

                {/* <img src={logo} className="logochatsm" alt="logo" width="200" height="100" /> */}
           
           </div>

            {/* <div className="rightpd"> */}
                    <div className="right">
                            <div className="scene pattern10">
                                <div id="showMeoverlay" className="overlayprmt blinkp">
                                    <p className='blinkp'> Please wait...</p><br/><br/>
                                </div>
                                {/* onClick={handleClick} */}
                                <div className="overlayimg">
                                        {/* <img src="https://img.freepik.com/free-photo/black-glitch-effect-texture_53876-94626.jpg?w=2000&t=st=1691720198~exp=1691720798~hmac=21fd794b033502046972783693dea56a23af1c625465f6d92796a5a2e7dacaf9" alt="overlay" /> */}
                                        <img src="https://img.freepik.com/free-photo/glitch-effect-black_53876-95597.jpg?w=1800&t=st=1691720202~exp=1691720802~hmac=c66025f0d918725e2f1de55897399dc83c4d618bae1ac9cacf321d2659b6bb5f" alt="overlay" />
                                </div>
                                
                                <div className="overlayprmt2">
                                    <div className="promptmbchat">
                                        <p className="fadein bottomtext">*click and drag Ø*</p>
                                        <p className="fadein2 bottomtext">*open mouth to teach Ø, face your camera*</p>
                                        <p className="fadein3 bottomtext" >*Ø is learning from you, blink and see*</p>
                                    </div>
                                </div>
                                <Morning />
                             </div>
                            
                    </div>
            {/* </div> */}
            <div className="marqueebot bottom">
                                    <p className="moving-textbot">
                                    {/* <span aria-hidden="true">All rights reserved. © MØRNING, 2023</span>
                                    <span aria-hidden="true">All rights reserved. © MØRNING, 2023</span>
                                    <span aria-hidden="true">All rights reserved. © MØRNING, 2023</span> */}
            All rights reserved. © MØRNING, 2023</p>
                    </div>
           </div>
            {/* </ul> */}
</div>
</section>
        </nav>
        </BrowserView>
        <MobileView>  
    <h1>404 *hiccup*... where are we?</h1>
    </MobileView>
        </div>
    );
}


export default Beforeyouenter;


