import React, { useEffect, useRef, useState } from "react";
import '../assets/main.css'
import '../morning.css'
import {Link} from "react-router-dom";
import useSound from 'use-sound'
import mySound from '../assets/yes.wav' 
import mySound2 from '../assets/no.wav'
import { BrowserView, MobileView } from 'react-device-detect';
// import egg from "../assets/eggbig.png";
// import Header from "../Header";
// import ReactAudioPlayer from 'react-audio-player';


function Beforeyouenter() {
    // const [playSound, { stop }] = useSound(mySound, { volume: 0.7 });
    const [playSound, { stop }] = useSound(mySound2, { volume: 0.2 });
   
    // pitch
    const [playbackRate, setPlaybackRate] = React.useState(0.75);
    const [play] = useSound(mySound, {
        playbackRate,
        volume: 0.2, 
        // `interrupt` ensures that if the sound starts again before it's
        // ended, it will truncate it. Otherwise, the sound can overlap.
        interrupt: true,
      });
      const handleClick = () => {
        setPlaybackRate(playbackRate + 0.8);
        play();
      };
      const handleClick2 = () => {
        setPlaybackRate(playbackRate + 0.8);
        play();
      };



 


    return (
        <div>
              <BrowserView>
              
        <nav className="container">
        {/* <div id="effectRef" className="fullsceane goo" style={effectContainerStyle}></div> */}
            {/* <ul className="mainText" > */}
            <div className="yesNo">
                {/* <p className="blur">THE Ø ARCHIVE</p> */}
                    <div className="miniText">
                            <p>This Website requires the use of your camera device.<br/><br/>
                                We won’t be capturing any data, it’s all just for a fun interaction with Ø.<br/>
                                You can also opt out of using the camera and interact with Ø in a more simple way.<br/><br/>
                                DO YOU WANT TO PROCEED ?
                            </p>
                    </div>
                    <div className="miniminiText">
                            <div className="shake space" >
                            <Link to="/Nope">
                            <button className="shakebutcon no" onClick={handleClick}>
                                No
                            </button>
                            </Link>
                            </div>


                            <div className="shake space " > 
                            <Link to="/Eggcrack">
                            <button className="shakebutcon yes" onMouseEnter={() => playSound()} onMouseLeave={() => stop()}>
                                Yes 
                            </button>
                            </Link>
                            </div>
                    </div>
            </div>
            {/* </ul> */}
        </nav>
        </BrowserView> 
        <MobileView>
        <nav className="container">
            <ul className="mainTextmobile" >
                <div>
                {/* <p className="blur">Ø ARCHIVE</p> */}
                <div className="miniTextmobile">
                <p>This Website requires the use of your camera device.<br/><br/>
                                We won’t be capturing any data, it’s all just for a fun interaction with Ø.<br/>
                                You can also opt out of using the camera and interact with Ø in a more simple way.<br/><br/>
                                DO YOU WANT TO PROCEED ?
                            </p>
                </div>
                <div className="miniminiTextmobile">
                <li className="shake spacemb shakebutconmb nomb" >
                <Link to="/Nope">
                <button onClick={handleClick}>
                    No
                </button>
                </Link>
                </li>
                <br/><br/>
                <li className="shake spacemb shakebutconmb yesmb" >
                <Link to="/Eggcrack">
                <button onClick={handleClick2}>
                     Yes 
                </button>
                </Link>
                </li>
                </div>
                </div>
            </ul>
        </nav>
        </MobileView>
        </div>
    );
}


export default Beforeyouenter;

