import React from 'react';
import '../assets/main.css'
import '../morning.css'
import {Link} from "react-router-dom";
import { BrowserView, MobileView} from 'react-device-detect';
import "../index.js";
// import logo from "../assets/MØRNING.png";
// import Morning from "./Morning";
// import Marquee from "react-fast-marquee";
// import Chatbot from 'react-chatbot-kit'
// import ActionProvider from '../ActionProvider';
// import MessageParser from '../MessageParser';
// import config from '../config';


// import { ReactComponent as YourSvg } from '../assets/svg.svg';

function Beforeyouenter(props) {

    return (
        <div>
              <BrowserView>
        <nav className="">
        <p>Ouch. You're darker than I thought</p>
        <Link to="/Chat">Back</Link>
       </nav>
        </BrowserView>
        <MobileView>
        <nav className="">
        <p>Ouch. You're darker than I thought</p>
        </nav>
        </MobileView>
        </div>
    );
}


export default Beforeyouenter;


