import React from 'react';
import '../assets/main.css'
import '../morning.css'
import {Link} from "react-router-dom";
import { BrowserView, MobileView } from 'react-device-detect';
// import egg2 from "../assets/eggblack.png";
// import Header from "../Header";
import "../index.js";


function Beforeyouenter(props) {

    return (
        <div>
              <BrowserView>
              <nav className="container">
{/* <Header/> */}

                {/* <ul className="mainText" >  */}
                    {/* <div className="shake yesNo bottomegg">
                    <img src={egg} width="400" height="200" />
                    </div> */}
                    <div className="shake yesNo ">
                    <Link to="/EggCrack">
                    <img class="shadowfilter" src="https://i.ibb.co/pxmHgZV/eggbigmb-1-781x976.png" alt="egg" width="350" height="550"/>
                    </Link>
                    </div>
                {/* </ul> */}
        </nav>
        </BrowserView>
        <MobileView>
        {/* <nav className="container"> */}
            {/* <h1 className="goo mainText">Ø</h1>
                <svg>
                    <defs>
                    <filter id="shadowed-goo">
                        <feGaussianBlur stdDeviation="1" result="blur" in="SourceGraphic"/>
                        <feColorMatrix result="filter3961" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 28 -10" mode="matrix" in="blur"/>
                    </filter>
                    <filter id="filter3961" x="0" y="0" width="1" height="1" color-interpolation-filters="sRGB">
                        <feColorMatrix in="SourceGraphic" result="result0" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 0.85 0" id="feColorMatrix3963"/>
                        <feGaussianBlur stdDeviation="7" in="SourceAlpha" id="feGaussianBlur3965"/>
                        <feSpecularLighting result="result1" specularExponent="25" specularConstant="0.89999998" surfaceScale="5" id="feSpecularLighting3967">
                        <feDistantLight azimuth="225" elevation="60" id="feDistantLight3969"/>
                        </feSpecularLighting>
                        <feComposite in2="result0" operator="atop" id="feComposite3971"/>
                        </filter>
                    </defs>
                </svg> */}
{/* id='showMe' */}
            {/* <div className="shake yesNo">
            <Link to="../EggTwo">
            <img src={egg2} alt="egg" width="400" height="200" />
            </Link>
            </div> */}
        {/* </nav> */}
        <nav className="container">
            {/* <ul className="mainTextmobile" > */}
            <div className="shake yesNo">
            <Link to="/EggCrack">
            <img class="shadowfilter" src="https://i.ibb.co/pxmHgZV/eggbigmb-1-781x976.png" alt="egg" width="350" height="550"/>
            </Link>
            </div>
            {/* </ul> */}
        </nav>
        </MobileView>
        </div>
    );
}


export default Beforeyouenter;

