import React, { useEffect, useRef, useState } from "react";
import {Link} from "react-router-dom";
import '../assets/main.css'
import '../morning.css'
import '../morningmb.css'
import { BrowserView, MobileView } from 'react-device-detect';
// import Header from "../Header";
// import { Effect } from "../components/Effect";


// const stackedDivStyle = {
//     position: "absolute",
//     top: 0,
//     left: 0,
//     width: "100vw",
//     height: "100vh",
//   };
  
//   const threeContainerStyle = {
//     ...stackedDivStyle,
//     zIndex: 3, // Higher z-index to place the three.js scene on top of other divs
//   };

// const effectContainerStyle = {
//     ...stackedDivStyle,
//     zIndex: 1, // Lower z-index to place the #effectRef div behind the three.js scene
//   };


  
  

function Home() {
    // const [loaded, setLoaded] = useState(false);
    // var mixer, inf;
    // const composerRef = useRef();
    // const rendererRef = useRef();
    // const sceneRef = useRef();
    // const meshRef = useRef();
    // const [bloomParams, setBloomParams] = useState({
    //   threshold: 0.0,
    //   strength: 0.0,
    //   radius: 0.0,
    // })
//     useEffect(() => {
//     const effectRef = document.getElementById("effectRef");
//     const effect = new Effect(effectRef);
// });


    return (

        <div>
                    <BrowserView>
        <nav className="containerhome">
        {/* <div id="effectRef" className="fullsceane" style={effectContainerStyle}></div> */}
            <div id="content-wrap">
            <ul className="mainTexthome" >
                {/* tip="*click to enter*" glow */}
                <div className="glow tooltipw" >
                <li >
                <Link to="/Beforeyouenter">ENTER LIMBØ</Link>
                </li>
                </div>
            </ul>
            </div>
        
        </nav>

        </BrowserView>
        <MobileView>
                 <nav className="containerhome">
                 {/* <div id="effectRef" className="fullsceane" style={effectContainerStyle}></div> */}
                 <div id="content-wrap">
            <ul className="mainTextsm" >
                <div className="glow" >
                <li >
                <Link to="/Beforeyouenter">ENTER LIMBØ</Link>
                </li>
                </div>
            </ul>
            </div>
        </nav>
        </MobileView>
        </div>
    );
}


export default Home;