import React, { useEffect, useRef, useState } from "react";
import "@tensorflow/tfjs";
import "@tensorflow/tfjs-backend-webgl";
import "@mediapipe/face_mesh";
import Webcam from "react-webcam";
import * as faceLandmarksDetection from "@tensorflow-models/face-landmarks-detection";

import * as THREE from "three";

// import Stats from "three/addons/libs/stats.module.js";

import { OrbitControls } from "three/addons/controls/OrbitControls.js";

import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { MeshoptDecoder } from "three/addons/libs/meshopt_decoder.module.js";

import { Effect } from "../components/Effect";
// import { RoomEnvironment } from "three/addons/environments/RoomEnvironment.js";
// import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import SpaceDustWrapper from "../components/ParticlesWrapper";

import { BrowserView, MobileView} from 'react-device-detect';
import "../assets/main.css";

// import { Link } from "react-router-dom";
// import video from '../assets/flame.mp4';
// import video from "../assets/flame.gif";
// import egg from "../assets/eggbig.png";


// import Header from "../Header";

// import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem, Divider } from "rc-menu";
import "rc-dropdown/assets/index.css";
// import ReactDOM from "react-dom";




function onSelect({ key }) {
  console.log(`${key} selected`);
}

function onVisibleChange(visible) {
  console.log(visible);
}

const menuCallback = () => (
  <Menu onSelect={onSelect}>
    <MenuItem disabled>PURPOSE: To provide information on the evolving narrative of Ø and provide context for people visiting the website. The archive documents the world we’ve created surrounding Ø and their journey through it so far…</MenuItem>
    <MenuItem key="1">
      ❤️‍🔥B.A.R NEWSLETTERS:
      <br /> <br />
      014 — AI FRIEND OR FOE
      <br />
      020 — UH OH, MEET Ø
      <br />
      021 — ARE WE LOSING TOUCH WITH REALITY?
      <br />
      024 — ENTER LIMBØ, WITH Ø
    </MenuItem>
    <Divider />
    <MenuItem key="2">
      ❤️‍🔥BTS OF CREATING Ø
      <br /> <br />
      LINK: CREATING Ø Insta Reel
      <br />
      ENTER LIMBØ, WITH Ø Insta Reel
      <br />
      FILES: HERVISIONS BTS JAE BTS (we spoke about possible option to allow the G drive for the BTS to be open source / viewable… TBC)
    </MenuItem>
    <MenuItem key="3">
      ❤️‍🔥WHATSAPP BROADCAST
      <br /> <br />
      ASSET: ANIMATED PROMO FLYER (link out to Insta post) CTA LINK: Sign up here
    </MenuItem>
    <MenuItem key="4">
      ❤️‍🔥MØRNING LINKS:
      <br /> <br />
      Instagram
      <br />
      Substack
      <br />
      Website
      <br />
      GDPR
    </MenuItem>
  </Menu>
);
// import { GUI } from 'three/addons/libs/lil-gui.module.min.js';

const containerStyle = {
  position: "relative",
};

const inputResolution = {
  width: 320,
  height: 240,
};

const videoConstraints = {
  width: inputResolution.width,
  height: inputResolution.height,
  facingMode: "user",
};

// const particleContainer = {
//   width: "100vw",
//   height: "100vh",
//   position: "absolute",
//   top: 0,
//   left: 0,
//   zIndex: 2, // Higher z-index to place the three.js scene on top of other divs
// };

const stackedDivStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
};

const threeContainerStyle = {
  ...stackedDivStyle,
  zIndex: 3, // Higher z-index to place the three.js scene on top of other divs
};

const effectContainerStyle = {
  ...stackedDivStyle,
  zIndex: 1, // Lower z-index to place the #effectRef div behind the three.js scene
};

function Home() {
  const [loaded, setLoaded] = useState(false);
  var mixer, inf;
  const composerRef = useRef();
  const rendererRef = useRef();
  const sceneRef = useRef();
  const meshRef = useRef();
  // const [bloomParams, setBloomParams] = useState({
  //   threshold: 0.0,
  //   strength: 0.0,
  //   radius: 0.0,
  // });

  useEffect(() => {
    // const clock = new THREE.Clock();
    const container = document.getElementById("scene");
    const effectRef = document.getElementById("effectRef");

    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 30);
    camera.position.set(0, 1, 4);

    const scene = new THREE.Scene();
    sceneRef.current = scene;

    console.log(window.innerWidth);

    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.useLegacyLights = false;
    renderer.shadowMap.enabled = true; // Enable shadows in the renderer
    renderer.shadowMap.type = THREE.PCFSoftShadowMap; // Adjust shadow quality
    // renderer.useLegacyLights = true;
    renderer.setClearColor(0x000000, 0);
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    rendererRef.current = renderer;

    container.appendChild(renderer.domElement);

    rendererRef.current.render(sceneRef.current, camera);

    const effect = new Effect(effectRef);

    new GLTFLoader().setMeshoptDecoder(MeshoptDecoder).load("/demo.glb", (gltf) => {
      const mesh = gltf.scene.children[0];
      scene.add(mesh);
      mesh.position.set(0, .07, 0);
      mixer = new THREE.AnimationMixer(mesh);
      const head = mesh.getObjectByName("mesh013");
      inf = head.morphTargetInfluences;

      mesh.traverse((obj) => {
        if (obj.isMesh) {
          obj.castShadow = true;
          obj.receiveShadow = true;
        }
      });
      // const gui = new GUI();
      // gui.close();

      // for (const [key, value] of Object.entries(head.morphTargetDictionary)) {
      //   gui.add(inf, value, 0, 1, 0.01)
      //     .name(key.replace('blendShape1.', ''))
      //     .listen(inf);
      // }
    });
    // const environment = new RoomEnvironment(renderer);
    // const pmremGenerator = new THREE.PMREMGenerator(renderer);

    // scene.environment = pmremGenerator.fromScene(new RoomEnvironment(renderer), 0.04).texture;
    // scene.environment = pmremGenerator.fromScene(environment).texture;

    // fog
    // scene.fog = new THREE.Fog(0xa0a0a0, 100, 1000);



    // light
    // let hemiLight1 = new THREE.HemisphereLight(0xffffff, 0x444444);
    // hemiLight1.position.set(700, 700, 700);
    // hemiLight1.intensity = 0.2;
    // scene.add(hemiLight1);

       // light
       let hemiLight1 = new THREE.HemisphereLight(0xffffff, 0x444444);
       hemiLight1.position.set(700, 700, 700);
       hemiLight1.intensity = 0.2;
       scene.add(hemiLight1);
   
       let spotLight = new THREE.PointLight(0xece8e5, 2);
       spotLight.position.set(0, .5, .3);
       spotLight.intensity = .3;
       // SpotLight.color.setHex( 0xff0000 );
       scene.add(spotLight);
   
       let spotLight2 = new THREE.PointLight(0xcba994, 2);
       spotLight2.position.set(.3, 1.5, .2);
       spotLight2.intensity = .4;
       // SpotLight.color.setHex( 0xff0000 );
       scene.add(spotLight2);
   
       let spotLight3 = new THREE.PointLight(0xcba994, 2);
       spotLight3.position.set(-.3, .5, 0);
       spotLight3.intensity = .4;
       // SpotLight.color.setHex( 0xff0000 );
       scene.add(spotLight3);
   
       let spotLight5 = new THREE.PointLight(0xcba994, 2);
       spotLight5.position.set(.6, .5, 0);
       spotLight5.intensity = .4;
       // SpotLight.color.setHex( 0xff0000 );
       scene.add(spotLight5);
   
       let spotLight4 = new THREE.PointLight(0xcba994, 2);
       spotLight4.position.set(1.3, .5, 1);
       spotLight4.intensity = 4;
       // SpotLight.color.setHex( 0xff0000 );
       scene.add(spotLight4);
   
       let ambientLight = new THREE.AmbientLight(0xffffff);
       ambientLight.position.set(.3, 1.5, 1.2);
       ambientLight.intensity = 0.4;
       scene.add(ambientLight);          


    // // Replace the HemisphereLight with a DirectionalLight for shadows
    // const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    // directionalLight.position.set(10, 20, 10);
    // directionalLight.castShadow = true; // Enable shadow casting
    // scene.add(directionalLight);

    // // Set up shadow properties for the light
    // directionalLight.shadow.mapSize.width = 1024; // Optional: adjust the shadow map size
    // directionalLight.shadow.mapSize.height = 1024;
    // directionalLight.shadow.camera.near = 0.5; // Optional: adjust the near and far clipping plane of the shadow camera
    // directionalLight.shadow.camera.far = 50;
    // directionalLight.shadow.camera.top = 10; // Optional: adjust the shadow camera's frustum parameters
    // directionalLight.shadow.camera.bottom = -20;
    // directionalLight.shadow.camera.left = -20;
    // directionalLight.shadow.camera.right = 20;

    // controls
    const controls = new OrbitControls(camera, renderer.domElement);
    // controls.addEventListener( 'change', renderer );
    controls.enableDamping = true;
    controls.minDistance = 3.5;
    controls.maxDistance = 5;
    // controls.minAzimuthAngle = -Math.PI / 2;
    // controls.maxAzimuthAngle = Math.PI / 2;
    controls.maxPolarAngle = Math.PI / 1.8;
    controls.target.set(0, 1, 0);
    // controls.enableZoom = false;
    controls.enableZoom = true;
    controls.autoRotate = true;


    const shadowGeometry = new THREE.PlaneGeometry(20, 20);
    const shadowMaterial = new THREE.ShadowMaterial();
    shadowMaterial.opacity = 0.6;
    const shadowPlane = new THREE.Mesh(shadowGeometry, shadowMaterial);
    shadowPlane.receiveShadow = true;
    shadowPlane.rotation.x = -Math.PI / 2;
    shadowPlane.position.y = 0.1;
    scene.add(shadowPlane);

    // ground
    // const geo = new THREE.BufferGeometry(2000, 2000, 8, 8);
    // const mat = new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.DoubleSide });
    // const plane = new THREE.Mesh(geo, mat);
    // scene.add(plane);
    // plane.rotateX( - Math.PI / 2);

    // var texture, material, plane;


    
    
    // const geometry = new THREE.PlaneGeometry( 20, 20, 8, 8 );
    // const material = new THREE.MeshBasicMaterial( {color: 0xffffff, side: THREE.DoubleSide} );
    // const plane = new THREE.Mesh( geometry, material );
    // scene.add( plane );
    // plane.rotateX( - Math.PI / 2);
    const geometry = new THREE.PlaneGeometry(10, 12); // width, height, no depth for plane
    var texture = new THREE.TextureLoader().load(
      "https://i.ibb.co/s1srfRC/background-image.png"
    ); // remove color = ...
    
    const material = new THREE.MeshBasicMaterial({
      // color: 0x000000,
      side: THREE.DoubleSide,
      map: texture // texture as a map for material
    });
    const plane = new THREE.Mesh(geometry, material); // mesh takes just two parameters
    scene.add(plane);
    plane.rotateX( - Math.PI / 2);
    
   
    scene.fog = new THREE.Fog(0x000000, 0, 500 );
    // fps thing
    // const stats = new Stats();
    // container.appendChild(stats.dom);

    const setupBloomEffect = () => {
      const renderScene = new RenderPass(scene, camera);
      // const bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, window.innerHeight), 1.5, 0.4, 0.85);
      // bloomPass.threshold = bloomParams.threshold;
      // bloomPass.strength = bloomParams.strength;
      // bloomPass.radius = bloomParams.radius;

      composerRef.current = new EffectComposer(renderer);
      composerRef.current.addPass(renderScene);
      // composerRef.current.addPass(bloomPass);
    };


    var spotLight6 = new THREE.SpotLight( 0xffffff );
    spotLight6.position.set( 1, 2, 1 );

    spotLight6.castShadow = true;

    spotLight6.shadowMapWidth = 10;
    spotLight6.shadowMapHeight = 10;

    spotLight6.shadowCameraNear = 5;
    spotLight6.shadowCameraFar = 2;
    spotLight6.shadowCameraFov = 3;
    
    spotLight6.intensity = .8;

    scene.add( spotLight6 );
    // add to the scene
    //scene.add(pointLight);
    var angle= 0;


    renderer.setAnimationLoop(() => {
      angle-=0.01;
  
      spotLight6.position.x =2+2*Math.sin(angle);
      spotLight6.position.y =2+2*Math.cos(angle);
    
      // composerRef.current.render();
      renderer.render(scene, camera);
      controls.update();
      // controls.autoRotate();
    });
    setupBloomEffect();

    window.addEventListener("resize", () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    });
  }, []);

  const runDetector = async (video) => {
    const model = faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh;
    const detectorConfig = {
      runtime: "tfjs",
    };
    const detector = await faceLandmarksDetection.createDetector(model, detectorConfig);
    const detect = async (net) => {
      const estimationConfig = { flipHorizontal: false };
      const faces = await net.estimateFaces(video, estimationConfig);
      requestAnimationFrame(() => {
        if (faces[0]) {
          let keyPoint = faces[0].keypoints;
          var lip_width = Math.sqrt((keyPoint[62].x - keyPoint[292].x) * (keyPoint[62].x - keyPoint[292].x) + (keyPoint[62].y - keyPoint[292].y) * (keyPoint[62].y - keyPoint[292].y));
          var lip_height = Math.sqrt((keyPoint[13].x - keyPoint[14].x) * (keyPoint[13].x - keyPoint[14].x) + (keyPoint[13].y - keyPoint[14].y) * (keyPoint[13].y - keyPoint[14].y));
          inf[2] = (lip_height / lip_width) * 3;
          
          
        var left_eye_width = Math.sqrt((keyPoint[33].x - keyPoint[133].x) * (keyPoint[33].x - keyPoint[133].x) + (keyPoint[33].y - keyPoint[133].y) * (keyPoint[33].y - keyPoint[133].y));
          var left_eye_height = Math.sqrt((keyPoint[159].x - keyPoint[145].x) * (keyPoint[159].x - keyPoint[145].x) + (keyPoint[159].y - keyPoint[145].y) * (keyPoint[159].y - keyPoint[145].y));
          inf[0] = 1 - (left_eye_height / left_eye_width - 0.18) / 0.25;

          // if (inf[0] > 0.8) inf[0] *= 1;
          // else if (inf[0] < 0.6) inf[0] = -1;
          var right_eye_width = Math.sqrt((keyPoint[362].x - keyPoint[263].x) * (keyPoint[362].x - keyPoint[263].x) + (keyPoint[362].y - keyPoint[263].y) * (keyPoint[362].y - keyPoint[263].y));
          var right_eye_height = Math.sqrt((keyPoint[386].x - keyPoint[374].x) * (keyPoint[386].x - keyPoint[374].x) + (keyPoint[386].y - keyPoint[374].y) * (keyPoint[386].y - keyPoint[374].y));
          inf[1] = 1 - (right_eye_height / right_eye_width - 0.18) / 0.25;
        }
      });
    };
    setInterval(() => {
      detect(detector);
    }, 100);
  };

  const handleVideoLoad = (videoNode) => {
    const video = videoNode.target;
    if (video.readyState !== 4) return;
    if (loaded) return;
    runDetector(video);
    setLoaded(true);
  };


  return (
    <div>
    <MobileView>  
    
    <div className="containermorningmb" style={containerStyle}>
    <div className="innerchatmb">
      <div id="scene" style={threeContainerStyle}>
      {/* <Header/> */}
        {/* <FooterScene /> */}
      </div>
      <div id="effectRef" style={effectContainerStyle}></div>
  
      <div style={{ position: "absolute", bottom: "0px", width: "100%" }}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
          {" "}
          <Webcam width={inputResolution.width} height={inputResolution.height} style={{ visibility: "hidden" }} videoConstraints={videoConstraints} onLoadedData={handleVideoLoad} />{" "}
        </div>
      </div>

      {loaded ? <></> : <header className="mainTextchatsm loading"></header>}
      </div>
    </div>
    </MobileView>

    <BrowserView>
    <h1>404 *hiccup*... where are we?</h1>
    </BrowserView>
    </div>
  );
}

export default Home;
