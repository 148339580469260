import React from 'react';
import '../assets/main.css'
import '../morning.css'
import {Link} from "react-router-dom";
import { BrowserView, MobileView } from 'react-device-detect';
// import egg from "../assets/eggbig.png";
// import Header from "../Header";
import "../index.js";


function Beforeyouenter(props) {

    return (
        <div>
              <BrowserView>
        <nav className="container">
{/* <Header/> */}

                {/* <ul className="mainText" >  */}
                    {/* <div className="shake yesNo bottomegg">
                    <img src={egg} width="400" height="200" />
                    </div> */}
                    <div className="shake yesNo ">
                    <Link to="/EggCrack">
                    <img class="shadowfilter" src="https://i.ibb.co/pxmHgZV/eggbigmb-1-781x976.png" alt="egg" width="350" height="550"/>
                    </Link>
                    </div>
                {/* </ul> */}
        </nav>
        
        </BrowserView>
        <MobileView>
        <nav className="container">
            {/* <ul className="mainTextmobile" > */}
            <div className="shake yesNo">
            <Link to="/EggCrack">
            <img class="shadowfilter" src="https://i.ibb.co/pxmHgZV/eggbigmb-1-781x976.png" alt="egg" width="350" height="550"/>
            </Link>
            </div>
            {/* </ul> */}
        </nav>
        </MobileView>
        </div>
    );
}


export default Beforeyouenter;

