// App.js 
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import Morning from "./pages/Morning";
import Beforeyouenter from "./pages/Beforeyouenter";
import Nope from "./pages/Nope";
import Egg from "./pages/Egg";
import EggTwo from "./pages/EggTwo";
import EggCrack from "./pages/EggCrack";
import Chat from "./pages/Chat";
import Chatnocam from "./pages/Chatnocam";
import Chatclose from "./pages/Chatclose";
import Chatclosemb from "./pages/Chatclosemb";
import Chatclosenocam from "./pages/Chatclosenocam";
import Chatclosembnocam from "./pages/Chatclosembnocam";
import EggCracknope from "./pages/EggCracknope";
import Manifesto from "./pages/Manifesto";
import Manifestonocam from "./pages/Manifestonocam";
import Night from "./pages/Night";
import Burn from "./pages/Burn";
// import "./assets/main.css";


const App = () => {
 return (
    <> 
    {/* <Header/> */}
    {/* <BrowserRouter> */}
      
       <Routes>
       <Route  path="/" element={<Home />} />
        <Route path="/Morning" element={<Morning />} />
        <Route path="/Beforeyouenter" element={<Beforeyouenter />} />
        <Route path="/Nope" element={<Nope />} />
        <Route path="/Egg" element={<Egg />} />
        <Route path="/EggTwo" element={<EggTwo />} />
        <Route path="/EggCrack" element={<EggCrack />} />
        <Route path="/Chat" element={<Chat />} />
        <Route path="/Chatnocam" element={<Chatnocam />} />
        <Route path="/Chatclose" element={<Chatclose />} />
        <Route path="/Chatclosemb" element={<Chatclosemb />} />
        <Route path="/Chatclosenocam" element={<Chatclosenocam />} />
        <Route path="/Chatclosembnocam" element={<Chatclosembnocam />} />
        <Route path="/EggCracknope" element={<EggCracknope />} />
        <Route path="/Manifesto" element={<Manifesto />} />
        <Route path="/Manifestonocam" element={<Manifestonocam />} />
        <Route path="/Night" element={<Night />} />
        <Route path="/Burn" element={<Burn />} />
        <Route path="*" element={<NoPage />} />
       </Routes>
      {/* </BrowserRouter> */}
    </>
 );
};

export default App;