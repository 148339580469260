import React from 'react';
import '../assets/main.css'
import '../morningmb.css'
import {Link} from "react-router-dom";
import { BrowserView, MobileView } from 'react-device-detect';
import "../index.js";
import logo from "../assets/logo.gif";
// import Nightmb from "../pages/Morning";
import Morningmb from "../pages/Morningmb";
import Marquee from "react-fast-marquee";
// import Chatbot from 'react-chatbot-kit'
// import ActionProvider from '../ActionProvider';
// import MessageParser from '../MessageParser';
// import config from '../config';


import { ReactComponent as YourSvg } from '../assets/svg.svg';

import useSound from 'use-sound'
// import mySound from '../assets/talk.mp3'


function Beforeyouenter(props) {
       // pitch
    //    const [playbackRate, setPlaybackRate] = React.useState(0.75);
    //    const [play] = useSound(mySound, {
    //        playbackRate,
    //        volume: 0.3,
    //        // `interrupt` ensures that if the sound starts again before it's
    //        // ended, it will truncate it. Otherwise, the sound can overlap.
    //        interrupt: true,
    //      });
    //      const handleClick = () => {
    //        setPlaybackRate(playbackRate);
    //        play();
    //      };
    return (
        <div>
              <BrowserView>
              <h1>404 *hiccup*... where are we?</h1>
        </BrowserView>
        <MobileView>
        <nav className="containerchat">
        <section className="pattern10">
        <YourSvg className="svg"/>
            <ul className="outtermb">
            <div className="innermb">

                <div id="containermb">
                <a href={"https://www.morning.fyi/"} className="marqueemobile">
                                    {/* <p class="moving-text">@MØRNING.FYI</p> */}
                               
                                <Marquee>
                                &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI 
                                &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI <br/>
                                &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI <br/>
                                &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI <br/>
                                &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI <br/>
                                &nbsp;&nbsp;  @MØRNING.FYI &nbsp;&nbsp;     @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI &nbsp;&nbsp;    @MØRNING.FYI <br/>
                                </Marquee>
                </a>
                </div>   


                <div className="logosec">
                <Link className="logombchat" to="/chatclosemb">
                                    <img src={logo} alt="logo" width="80" height="40" />
                                </Link>
                </div>


                <div className="activesec" >
                {/* id="showMeoverlay" onClick={handleClick}*/}
                                    <div id="showMeoverlay" className="overlayprmtmb blinkp">
                                                        <p> Please wait...</p><br/><br/>
                                    </div>
                                    <div className="overlayprmt2mb">
                                                        <div className="promptmbchat">
                                                            <p className="fadein bottomtext">*tap and drag Ø*</p>
                                                            <p className="fadein2 bottomtext">*open mouth to teach Ø, face your camera*</p>
                                                            <p className="fadein3 bottomtext" >*Ø is learning from you, blink and see*</p>
                                                        </div>
                                    </div>
                                  <Morningmb />
                </div>  

                

            

                <div className="unactivesec">
                    
                {/* <Link className="logombchat" to="/chatclosemb">
                    <img src={logo} alt="logo" width="80" height="40" />
                </Link> */}

{/* <div className="chatbtnmbcont"> */}
                <div className="chatbtn">
                <Link className="mainTextchatsmmb" to="/Manifesto">Manifesto</Link>
                </div>
                <div className="chatbtn">
                <Link className="mainTextchatsmmb" to="/Chat">Chat now</Link>
                </div>
                </div>  
{/* </div>                     */}
             </div> 
            </ul>
            </section>
        </nav>
        </MobileView>
        
        </div>


    );
}



export default Beforeyouenter;


