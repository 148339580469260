class ActionProvider {
  constructor(createChatBotMessage, setStateFunc) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
  }

  handleWhatislimbo = () => {
    const message = this.createChatBotMessage(
      "Ø: LIMBØ is my first home since I (re)birthed…🥚, It’s a cosy space for conversations between me… *hiccup*... MØRNING and you… to help us explore a more mutual human x machine relationship together 🖤 ",
      {
        delay: 1000,
        widget: "whereislimbo",
      }
    );


    this.updateChatbotState(message);
  };


  handleWhereislimbo = () => {
    const message = this.createChatBotMessage(
      // <Link id="showMecrackm" to="/Chatclosemb"></Link>
      "Ø: It exists on Whatsapp… repurposing an existing platform that people have access to globally… so we can welcome and embrace more people on this journey with us 🌹" ,
      {
        delay: 1000,
        widget: "limbo",
      }
    );
    // LIMBØ


    this.updateChatbotState(message);
  };


  // handleLIMBØ = () => {
  //   const message = this.createChatBotMessage(
  //     "Ø: It exists on Whatsapp… repurposing an existing platform that people have access to globally… so we can welcome and embrace more people on this journey with us 🌹" ,
  //     {
  //       delay: 1000,
  //       widget: "limbo",
  //     }
  //   );
  //   // LIMBØ


  //   this.updateChatbotState(message);
  // };


  updateChatbotState(message) {
    // NOTICE: This function is set in the constructor, and is passed in from the top level Chatbot component. The setState function here actually manipulates the top level state of the Chatbot, so it's important that we make sure that we preserve the previous state.

    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, message],
    }));
  }
}

export default ActionProvider;