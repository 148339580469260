import React from "react";
import "../assets/main.css";
import "../morning.css";
// import {Link} from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
// import egg from "../assets/eggbig.png";
// import Header from "../Header";
import "../index.js";
import {Link} from "react-router-dom";
// import MyVideo from "../assets/egg.webm";
 

function Beforeyouenter(props) {
  return (
    <div>
      <BrowserView>
        <nav className="container">
          {/* <a class="videoegg" href="/"> */}
          {/* ENTER LIMBØ blinkpstill */}
            <div className="blinkp center">
            <div id="showMecrackw" className="promptmb">
                *hatching...*
              </div>
            </div>
            
            <div className="blinkp center">
            <div id="showMecrackm" className="promptmb">
            <Link id="showMecrackm" to="/Chatclosenocam">
                *tap here to meet Ø*
            </Link>
              </div>
            </div>
           
            <img className="vid"
              src="https://i.ibb.co/WyYLvt9/ezgif-com-optimize.gif"
              alt="egg"
              width="105%"
              height="100%"
            />
          {/* </a> */}
          
        </nav>
      </BrowserView>
      <MobileView>
        <nav className="container">
        {/* <Link to="/Chatclosemb"> */}
          {/* ENTER LIMBØ */}
          {/* <a href="/Chatclosemb"> */}
            {/* <div class="promptmb center blinkp">
              <div id="showMecrack">*tap to Ø**</div>
            </div> */}
            <div className="promptmb center blinkp" style={{ transform: "translateX(-50%)" }}>
            <div id="showMecrackw" class="promptmb">
                *hatching...*
              </div>
            </div>
            <div className="promptmb center blinkp" style={{ transform: "translateX(-50%)" }}>
            <div className="promptmb">
            <Link id="showMecrackm" to="/Chatclosembnocam">
                *tap here to meet Ø*
            </Link>
              </div>
            </div>
            <div className="vidmb centermb">
              <img className="vid"
                src="https://i.ibb.co/WyYLvt9/ezgif-com-optimize.gif"
                alt="egg"
                width="200%"
                height="100%"
              />
            </div>
          {/* </a> */}
          {/* </Link> */}
        </nav>
      </MobileView>
    </div>
  );
}

export default Beforeyouenter;
